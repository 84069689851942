<route>
{
  "meta": {
    "permission": [
      "sales.view_orderstatus"
    ]
  }
}
</route>

<template>
  <v-container fluid class="order-status">
    <!-- Título -->
    <v-toolbar dark color="secondary">
      <v-toolbar-items v-show="mode === 2 || cloneMode">
        <v-btn v-if="orderStatusBtn.edit" text dark @click="changeMode(1)">
          <v-icon>fa-chevron-left</v-icon>
        </v-btn>
      </v-toolbar-items>
      <v-spacer></v-spacer>
      <v-toolbar-title class="font-weight-black">
        <span v-if="cloneMode">{{ $t('selectClone') }}</span>
        <span v-else>{{ $tc('orderstatus', 2) }}</span>
        <span v-if="template">{{ ` (${templateSelected.name})` }}</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items v-show="cloneMode">
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-btn
              v-if="orderStatusBtn.add"
              v-on="on"
              text
              color="white"
              @click="cloneTemplate()"
              dark
            >
              <v-icon>fa-save</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('clone') }}</span>
        </v-tooltip>
      </v-toolbar-items>
      <v-toolbar-items
        v-if="status.length > 0"
        v-show="mode === 2 && status[0].company"
      >
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-btn
              v-if="orderStatusBtn.add"
              v-on="on"
              text
              color="white"
              @click="open()"
              dark
            >
              <v-icon>fa-plus</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('new_order_status') }}</span>
        </v-tooltip>
      </v-toolbar-items>
    </v-toolbar>

    <!-- Estados -->
    <v-card-text>
      <v-alert v-if="cloneMode" outlined type="info">
        {{ $t('selectClone2') }}
      </v-alert>

      <v-row v-show="mode === 1">
        <v-col
          v-show="loading"
          v-for="x in [1, 2, 3, 4]"
          :key="'skeleton-' + x"
          cols="12"
          sm="6"
          md="3"
        >
          <v-skeleton-loader type="article" class="mx-auto"></v-skeleton-loader>
        </v-col>
        <v-col
          v-for="item in templateFiltered"
          :key="'sold-' + item.pk"
          cols="12"
          md="6"
          lg="3"
        >
          <v-card
            v-if="orderStatusBtn.view"
            :class="getCardClass(item)"
            @click="handleCardClick(item)"
          >
            <v-card-text>
              <div class="d-flex justify-content-between">
                <div class="primary--text">
                  <span class="font-weight-black">{{ `${$t('name')}: ` }}</span>
                  <span>{{ item.name }}</span>
                </div>
                <div>
                  <v-tooltip v-if="!cloneMode && item.orderNumber > 0" top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-if="orderStatusBtn.add"
                        text
                        v-on="on"
                        x-small
                        color="primary"
                        @click.stop="activeCloneMode(item.pk)"
                      >
                        <v-icon small>fa-clone</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('clone') }}</span>
                  </v-tooltip>
                  <v-chip pill color="primary" x-small>
                    {{ item.orderNumber }}
                  </v-chip>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <draggable
        v-if="mode === 2"
        class="layout row wrap mt-0 mb-0"
        :group="{ name: 'Status', put: false }"
        @start="drag = true"
        @end="drag = false"
        @change="update()"
        v-model="status"
      >
        <!-- Order Card -->
        <v-col
          v-for="statu in status"
          class="order-card"
          cols="12"
          sm="6"
          md="4"
          lg="3"
          :key="statu.pk"
        >
          <!-- Card Header -->
          <v-toolbar class="rounded" :style="getToolbarStyle(statu)">
            #{{ statu.position }} {{ $tc(statu.name, 1) }}
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-if="orderStatusBtn.edit"
                    v-on="on"
                    v-show="statu.substatusVals.length !== 0"
                    small
                    text
                    dark
                    color="primary"
                    @click="changeShowStatus(statu.pk)"
                  >
                    <v-icon
                      small
                      dark
                      v-text="
                        showStatus[statu.pk] ? 'fa-arrow-up' : 'fa-arrow-down'
                      "
                    ></v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('deploy') }}</span>
              </v-tooltip>
            </v-toolbar-items>
            <v-toolbar-items v-if="canEditOrDelete(statu)">
              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <v-btn color="primary" text small dark v-on="on">
                    <v-icon>fa-ellipsis-v</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-if="canAddSubStatus(statu)"
                    @click.prevent="
                      open(undefined, statu.pk, statu.substatusVals, {
                        dark: statu.dark,
                        color: statu.color
                      })
                    "
                  >
                    <v-list-item-icon>
                      <v-icon
                        class="mr-2"
                        color="primary"
                        :small="$vuetify.breakpoint.smAndDown"
                        v-text="'fa-plus'"
                      ></v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ $t('next_sub_state') }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    v-if="canEditStatus(statu)"
                    @click.prevent="open(statu)"
                  >
                    <v-list-item-icon>
                      <v-icon
                        class="mr-2"
                        color="primary"
                        :small="$vuetify.breakpoint.smAndDown"
                        v-text="'fa-edit'"
                      ></v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>{{ $t('edit') }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    v-if="canDeleteStatus(statu)"
                    @click.prevent="remove(statu)"
                  >
                    <v-list-item-icon>
                      <v-icon
                        class="mr-2"
                        color="red"
                        :small="$vuetify.breakpoint.smAndDown"
                        v-text="'fa-trash'"
                      ></v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ $tc('delete', 1) }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-toolbar-items>
          </v-toolbar>
          <!-- End Card Header -->
          <!-- Card Body -->
          <v-col v-if="orderStatusBtn.edit" class="pt-2 pb-0">
            <draggable
              v-show="showStatus[statu.pk]"
              class="layout row wrap my-0"
              :group="{ name: 'SubStatus', put: false }"
              @change="update(statu.substatusVals)"
              @start="drag2 = true"
              @end="drag2 = false"
              v-model="statu.substatusVals"
            >
              <div
                v-for="(attr_selected, index) in statu.substatusVals"
                :key="'card-' + attr_selected.name + '-' + index"
                class="status-subitem"
                :style="getSubStatusStyle(attr_selected)"
                @click="statu.company !== null ? open(attr_selected) : ''"
              >
                <span>
                  {{
                    `#${attr_selected.position} ${$tc(attr_selected.name, 1)}`
                  }}
                </span>
                <div class="d-flex g-2">
                  <v-icon v-if="attr_selected.push" dark small>fa-bell</v-icon>
                  <v-icon v-if="attr_selected.email" dark small>
                    fa-envelope
                  </v-icon>
                  <v-icon v-if="attr_selected.whatsapp" dark small>
                    fa-comment
                  </v-icon>
                  <v-btn
                    v-if="orderStatusBtn.delete && attr_selected.dynamic"
                    small
                    text
                    dark
                    color="secondary"
                    @click.stop="remove(attr_selected)"
                  >
                    <v-icon dark small>fa-times</v-icon>
                  </v-btn>
                </div>
              </div>
            </draggable>
          </v-col>
          <!-- End - Card Body -->
        </v-col>
        <!-- End - Order Card -->
      </draggable>
    </v-card-text>

    <!-- Modal del formulario -->
    <StatusDialog
      ref="StatusDialog"
      :to-edit="toEdit"
      @done="getOrderStatus"
      v-model="dialog"
    />
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import draggable from 'vuedraggable'
import StatusDialog from '../../components/orderstatus/modal.vue'

export default {
  components: {
    draggable,
    StatusDialog
  },
  data() {
    return {
      status: [],
      showStatus: {},
      drag: false,
      drag2: false,
      loading: false,
      mode: 1,
      template: null,
      templateSelected: null,
      templates: [],
      sta: {},
      cloneMode: false,
      selectedClone: {
        template: null,
        templates: []
      },
      dialog: false,
      toEdit: null,
      searchTemplate: ''
    }
  },
  computed: {
    templateFiltered() {
      return this.searchTemplate
        ? this.templates.filter((item) => {
            const templateStr = `${item.template ? item.template + ' - ' : ''}${
              item.name
            }`.toLowerCase()
            return templateStr.includes(this.searchTemplate.toLowerCase())
          })
        : this.templates
    },
    ...mapGetters({
      getPermissions: 'session/getPermissions',
      me: 'session/me',
      company: 'company/getCompanyData'
    }),
    orderStatusBtn() {
      return {
        add: this.getPermissions(['sales.add_orderstatus']),
        edit: this.getPermissions(['sales.change_orderstatus']),
        delete: this.getPermissions(['sales.delete_orderstatus']),
        view: this.getPermissions(['sales.view_orderstatus'])
      }
    }
  },
  methods: {
    ...mapActions({
      showConfirmation: 'confirmation/confirmationValue'
    }),
    /**
     * open
     * Esta función establece el elemento a editar (`toEdit`).
     * Si no se proporciona un elemento (`item`), se crea un nuevo estado
     * utilizando la función `createNewStatus` con los parámetros `root`,
     * `sub` y `colors`.
     * Luego, se activa el diálogo estableciendo la propiedad `dialog` en
     * `true`.
     *
     * @param {Object} item - El objeto que se desea editar. Si es `null`
     *                        o `undefined`, se crea un nuevo estado.
     * Parámetros utilizado para crear un nuevo estado si no se proporciona
     * `item`.
     * @param {Object} root
     * @param {Object} sub
     * @param {Array} colors
     *
     * Miguel E. Villamizar R. <mevr02 at gmail.com>
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    open(item, root, sub, colors) {
      this.toEdit = item || this.createNewStatus(root, sub, colors)
      this.dialog = true
    },
    templateChange(val) {
      this.template = val
    },
    /**
     * createNewStatus
     * Esta función devuelve un objeto que representa un nuevo estado.
     * Las propiedades del objeto se establecen según los parámetros
     * proporcionados y valores predeterminados:
     *
     * @param {Object} root - Valor que determina si el estado es padre.
     * @param {Array} sub - Lista de estados secundarios que afecta la
     *                      posición.
     * @param {Object} colors - Objeto que contiene propiedades de color
     *                          para el estado.
     * @returns {Object} - Un nuevo objeto de estado con las propiedades
     *                     definidas.
     *
     * Miguel E. Villamizar R. <mevr02 at gmail.com>
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    createNewStatus(root, sub, colors) {
      return {
        pk: '',
        color: colors?.color || '#FFFFFF',
        root,
        position: root ? sub.length + 1 : this.status.length + 1,
        template: this.template,
        name: '',
        hidden: false,
        editable: false,
        dynamic: true,
        dark: colors?.dark || false
      }
    },
    /**
     * handleCardClick
     * Esta función verifica si el modo de clonación está activado y el
     * permiso de estado de orden tiene la opción de agregar.
     * Si ambas condiciones son verdaderas, se llama a la función
     * `selectedForClone` con el identificador del elemento.
     * Si el botón de estado de orden tiene la opción de editar, se llama a
     * la función `templateChange` también con el identificador del elemento.
     *
     * @param {Object} item - El objeto que representa la tarjeta sobre la
     *                        que se ha hecho clic.
     *
     * Miguel E. Villamizar R. <mevr02 at gmail.com>
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    handleCardClick(item) {
      if (this.cloneMode && this.orderStatusBtn.add) {
        this.selectedForClone(item.pk)
      } else if (this.orderStatusBtn.edit) {
        this.templateChange(item.pk)
      }
    },
    /**
     * cloneTemplate
     * Esta función llama a la API para clonar el estado seleccionado
     * utilizando `selectedClone`.
     *
     * Miguel E. Villamizar R. <mevr02 at gmail.com>
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    async cloneTemplate() {
      await this.$api.sale.status.clone({ form: this.selectedClone })
      this.selectedClone = { template: null, templates: [] }
      this.getTemplate()
      this.cloneMode = false
    },
    /**
     * activeCloneMode
     * Esta función establece la plantilla seleccionada en
     * `selectedClone.template` y activa el modo de clonación.
     *
     * @param {Object} template - La plantilla que se desea seleccionar para
     *                            clonar.
     *
     * Miguel E. Villamizar R. <mevr02 at gmail.com>
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    activeCloneMode(template) {
      this.selectedClone.template = template
      this.cloneMode = true
    },
    /**
     * getCardClass
     * Esta función construye y devuelve un objeto que contiene clases CSS
     * basadas en el estado de selección de la plantilla clonada y si el
     * elemento está seleccionado.
     *
     * @param {Object} item - El objeto que representa la tarjeta.
     * @returns {Object} Un objeto con las clases CSS aplicables.
     *
     * Miguel E. Villamizar R. <mevr02 at gmail.com>
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    getCardClass(item) {
      return {
        listcard: true,
        selectedBase: this.selectedClone.template === item.pk,
        selectedClone: this.selectedClone.templates.includes(item.pk)
      }
    },
    /**
     * getToolbarStyle
     * Esta función genera un objeto de estilo que se aplica a la barra de
     * herramientas, utilizando el color del estado y determinando el color
     * del texto según si el estado es oscuro o no.
     *
     * @param {Object} statu - El objeto que representa el estado.
     * @returns {Object} Un objeto con propiedades de estilo CSS.
     *
     * Miguel E. Villamizar R. <mevr02 at gmail.com>
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    getToolbarStyle(statu) {
      return {
        background: `${statu.color}`,
        fontWeight: 700,
        color: statu.dark ? 'white' : 'black'
      }
    },
    /**
     * selectedForClone
     * Esta función verifica si el valor proporcionado ya está en la lista
     * de plantillas seleccionadas.
     * Si es así, lo elimina; de lo contrario, lo añade a la lista, siempre
     * que no sea la plantilla seleccionada.
     *
     * @param {string} val - El identificador del estado a seleccionar o
     *                       deseleccionar.
     *
     * Miguel E. Villamizar R. <mevr02 at gmail.com>
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    selectedForClone(val) {
      const index = this.selectedClone.templates.indexOf(val)
      if (index !== -1) {
        this.selectedClone.templates.splice(index, 1)
      } else if (val !== this.selectedClone.template) {
        this.selectedClone.templates.push(val)
      }
    },
    /**
     * getSubStatusStyle
     * Esta función genera un objeto de estilo que se aplica a un subestado,
     * utilizando su color y determinando el color del texto según si es
     * oscuro o no.
     *
     * @param {Object} attr_selected - El objeto que representa el subestado.
     * @returns {Object} Un objeto con propiedades de estilo CSS.
     *
     * Miguel E. Villamizar R. <mevr02 at gmail.com>
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    getSubStatusStyle(attr_selected) {
      return {
        'background-color': `${attr_selected.color} !important`,
        color: attr_selected.dark ? 'white' : 'black'
      }
    },
    /**
     * changeMode
     * Esta función establece el modo lista de plantillas o lista de estados
     * de una plantilla.
     *
     * @param {string} mode - El nuevo modo que se desea establecer.
     *
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    changeMode(mode) {
      this.mode = mode
      this.cloneMode = false
      this.selectedClone = { template: null, templates: [] }
    },
    /**
     * canAddSubStatus
     * Esta función retorna si se tiene permiso de añadir un subestado,
     * debe ser diferente de preprocess.
     *
     * @param {Object} statu - El objeto que representa el estado.
     * @return {boolean}
     *
     * Miguel E. Villamizar R. <mevr02 at gmail.com>
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    canAddSubStatus(statu) {
      return (
        this.orderStatusBtn.add &&
        statu.name !== 'preprocess' &&
        statu.company !== null
      )
    },
    canEditOrDelete(statu) {
      return this.canEditStatus(statu) || this.canDeleteStatus(statu)
    },
    /**
     * canEditStatus
     * Esta función determina si el usuario tiene permiso de editar un estado
     *
     * @param {Object} statu - El objeto que representa el estado.
     * @returns {boolean} `true` si se puede editar, `false` en caso contrario.
     *
     * Miguel E. Villamizar R. <mevr02 at gmail.com>
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    canEditStatus(statu) {
      return statu.company !== null && this.orderStatusBtn.edit
    },
    /**
     * canDeleteStatus
     * Esta función determina si se permite eliminar un estado, si el usuario
     * es administrador y si el estado es dinámico.
     *
     * @param {Object} statu - El objeto que representa el estado.
     * @returns {boolean} `true` si se puede eliminar, `false` en caso contrario.
     *
     * Miguel E. Villamizar R. <mevr02 at gmail.com>
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    canDeleteStatus(statu) {
      return (
        statu.name !== 'preprocess' &&
        statu.company !== null &&
        this.orderStatusBtn.delete
      )
    },
    /**
     * update
     * Esta función actualiza la posición de los estados en función del
     * estado proporcionado o de la lista actual de estados. Llama a la API
     * para realizar la actualización.
     *
     * @param {Array} sta - La lista de estados a actualizar.
     *
     * Miguel E. Villamizar R. <mevr02 at gmail.com>
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    async update(sta) {
      const statusToUpdate =
        sta ||
        this.status.map((state, index) => {
          state.position = index + 1
          return state
        })
      await this.$api.sale.status.updatePosition({ form: statusToUpdate })
    },
    /**
     * remove
     * Esta función verifica si el estado puede ser eliminado y en caso
     * afirmativo, muestra una confirmación antes de proceder con la
     * eliminación a través de la API.
     *
     * @param {Object} item - El objeto que representa el estado a eliminar.
     *
     * Miguel E. Villamizar R. <mevr02 at gmail.com>
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    remove(item) {
      if (item.canDelete) {
        this.showConfirmation({
          show: true,
          model: this.$tc('state', 1),
          title: this.$tc('delete', 1),
          type: 'error white--text',
          content: this.$t('confirm_delete'),
          alert: this.$t('success', {
            model: this.$tc('state', 1),
            action: this.$tc('delete', 2)
          }),
          confirm: () => this.$api.sale.status.remove({ pk: item.pk })
        })
      } else {
        this.$toast.error(this.$tc('delete_no'))
      }
    },
    /**
     * changeShowStatus
     * Esta función alterna la visibilidad del estado especificado por su
     * clave primaria (pk).
     *
     * @param {string} pk - El identificador del estado cuya visibilidad
     * se desea cambiar.
     *
     * Miguel E. Villamizar R. <mevr02 at gmail.com>
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    changeShowStatus(pk) {
      this.showStatus[pk] = !this.showStatus[pk]
    },
    /**
     * getOrderStatus
     * Esta función llama a la API para obtener la lista de estados y los
     * almacena en `status`.
     * También gestiona la visibilidad y actualiza la posición de los
     * estados según sea necesario.
     *
     * @param {Object} sta - El estado padre para el que se desean obtener
     *                       subestados.
     * @param {boolean} firsttime - Indica si es la primera vez que se
     *                              llama a esta función.
     *
     * Miguel E. Villamizar R. <mevr02 at gmail.com>
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    async getOrderStatus(sta, firsttime = false) {
      try {
        const response = await this.$api.sale.status.list({
          opt: { params: { template: this.template } }
        })
        this.status = response.data
        this.showStatus = this.status.reduce((acc, statu) => {
          acc[statu.pk] = acc[statu.pk] ?? false
          return acc
        }, {})
        if (firsttime) this.update()
        if (sta) {
          this.update(
            sta.root == null
              ? undefined
              : this.status.find((m) => m.pk == sta.root).substatusVals
          )
        }
        this.changeMode(2)
      } catch (error) {
        console.error(error)
      } finally {
        if (this.$store.getters.getIsloading) {
          this.$store.dispatch('actionLoading')
        }
        this.loading = false
      }
    },
    /**
     * getTemplate
     * Esta función llama a la API para obtener las plantillas de procesos
     * de producción y las almacena en `templates`.
     *
     * Miguel E. Villamizar R. <mevr02 at gmail.com>
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    async getTemplate() {
      this.loading = true
      try {
        const response = await this.$api.product.template.list({
          opt: { params: { orderStatus: true, company: this.company.pk } }
        })
        this.templates = response.data
      } finally {
        if (this.$store.getters.getIsloading) {
          this.$store.dispatch('actionLoading')
        }
        this.loading = false
      }
    }
  },
  watch: {
    'statusO.color': {
      handler(val) {
        if (typeof val === 'object') {
          this.statusO.color = val.hex
        }
      }
    },
    mode(val) {
      if (val === 1 && this.template) {
        const inx = this.templates.findIndex((m) => m.pk === this.template)
        if (inx !== -1) {
          this.$set(this.templates[inx], 'orderNumber', this.status.length)
        }
        this.template = null
      }
    },
    async template(val) {
      if (typeof val === 'number') {
        this.templateSelected = this.templates.find((item) => item.pk === val)
        await this.getOrderStatus(undefined, true)
      }
    }
  },
  created() {
    this.unwatch = this.$store.watch(
      () => this.$store.getters['confirmation/getConfirmationFinished'],
      async (val) => {
        if (val === this.$tc('state', 1)) {
          await this.getOrderStatus(this.sta)
          this.$store.dispatch('confirmation/confirmationFinished', false)
        }
      }
    )
  },
  beforeDestroy() {
    this.unwatch()
  },
  mounted() {
    if (this.company) {
      this.getTemplate()
    }
  }
}
</script>

<style lang="sass">
.order-status
  & > .v-toolbar
    // --------------------------------------------
    // Container del título de la toolbar principal
    // --------------------------------------------
    transform: skewX(-10deg) scaleX(calc(1 + 1/100))
    border-radius: 4px 4px 20px 20px
    .v-toolbar__content
      // ------------------------------
      // Título de la toolbar principal
      // ------------------------------
      transform: skewX(10deg) scaleX(calc(1 - 1/100))
      font-family: 'Trebuchet MS Italic'
  .order-card
    border-bottom: 1px solid var(--v-muted-base)
    padding-bottom: 1.25rem
    button.hide-buttons
      display: none
    &:hover
      button.hide-buttons
        display: inherit
    span.v-chip > span.v-chip__content > div.v-chip__close
      display: none
    span.v-chip:hover > span.v-chip__content > div.v-chip__close
      display: inherit
    span.v-chip
      margin-right: 1.8rem
      &:hover
        margin-right: 0rem
  .status-subitem
    display: flex
    justify-content: space-between
    border-radius: 4px
    padding: 8px 12px
    margin-bottom: 8px
    font-weight: 700
    width: 100%
    &:hover
      cursor: pointer

@media (600px < width)
  .order-status
    padding-left: 3rem
    padding-right: 3rem
    overflow-y: scroll

    .order-card span.v-chip
      width: 98%

    .order-card span.v-chip .v-chip__content
      display: block
      width: 100%
      text-overflow: ellipsis
      overflow: hidden
      white-space: nowrap

    .order-card span.v-chip
      margin-right: 0rem

    .order-card span.v-chip:hover > span.v-chip__content > div.v-chip__close
      position: absolute
      right: 0
      top: 0
      margin-right: 1px
      margin-top: -6px

.order-status .listcard
  &.selectedBase
    border-color: #b4c2cf
    background-color: rgb(245 245 245 / .2)
  &.selectedClone
    border-color: #21b954
    background-color: rgb(33 185 84 / .2)
</style>
